<template>
  <v-input
    :label="$t('components.website.productCard.availableColors')"
    :rules="rules"
    :error-messages="errorMessages"
    :vertical="true"
    :value="hasValidValue"
  >
    <v-btn-toggle v-model="internalValue" shaped class="mt-2">
      <v-btn
        v-for="(c, index) in stock"
        :key="`product-${productId}-color-${index}`"
        small
        class="btn-invert-text"
        active-class="btn-color-selected"
        :style="getColorBtnStyles(c)"
      >
        {{ c.name }}
      </v-btn>
    </v-btn-toggle>
  </v-input>
</template>

<script>
  export default {
    name: 'ProductColorPicker',
    props: {
      productId: [Number, String],
      stock: Array,
      value: [String, Number],
      rules: Array,
      errorMessages: Array,
    },
    data: vm => ({
      internalValue: vm.stock?.findIndex(s => s.name === vm.value),
    }),
    computed: {
      hasValidValue () {
        return Number.isSafeInteger(this.internalValue) && this.internalValue >= 0
      },
    },
    watch: {
      value () {
        this.internalValue = this.stock.findIndex(s => s.name === this.value)
      },
      stock () {
        this.internalValue = this.stock.findIndex(s => s.name === this.value)
      },
      internalValue () {
        if (Number.isSafeInteger(this.internalValue) && this.internalValue >= 0) {
          this.$emit('input', this.stock[this.internalValue].name)
        } else {
          this.$emit('input', undefined)
        }
      },
    },
    methods: {
      getColorBtnStyles (c) {
        return [
          `color: ${this.getSafeColor(c.color)} !important`,
          `background-color: ${this.getSafeColor(c.color)} !important`,
          `outline-color: ${this.getSafeColor(c.color)} !important`,
        ].join(';')
      },
      getSafeColor (c) {
        if (c && (typeof c === 'string' || typeof c === 'number')) {
          if (!(c + '').startsWith('#')) {
            c = '#' + c
          }
          if (c.length === 6) {
            return c.padEnd(7, '0')
          }
        }

        return c
      },
    },
  }
</script>
