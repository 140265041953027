<template>
  <v-form ref="form" v-model="valid">
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12">
          <product-size-picker
            v-model="size"
            :stock="productAvailableSizeStock"
            :product-id="productId"
            :rules="[ getRequiredRule() ]"
          />
        </v-col>
        <v-col cols="12" sm="12">
          <product-color-picker
            v-model="color"
            :stock="productAvailableColorStock"
            :product-id="productId"
            :rules="[ getRequiredRule() ]"
            @input="onColorChanged"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <div v-if="!size || !color">
        <v-alert type="warning">{{ pickedLabel }}</v-alert>
      </div>
      <div v-else-if="productIsPickedItemInStock" class="d-flex flex-row align-center">
        <span class="green--text me-3">{{ $t('components.website.productCard.availableInStock') }}</span>
        <span>{{ pickedLabel }}</span>
        <v-icon v-if="size && color" :color="pickedColorHex">mdi-record</v-icon>
        <span v-if="size && color">{{ $t('components.website.productCard.pickedLabelAfter') }}</span>
      </div>
      <div v-else class="d-flex flex-row align-center">
        <span class="orange--text me-3">{{ $t('components.website.productCard.notAvailableInStock') }}</span>
        <v-btn
          text
          x-small
          :loading="submittingContactMe"
          @click="onContactMeWhenAvailable"
        >
          <v-icon x-small>mdi-bell</v-icon>
          {{ $t('components.website.productCard.contactMeWhenAvailable') }}
        </v-btn>
      </div>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-col cols="5">
        <product-quantity-picker v-model="quantity" />
      </v-col>
      <v-btn
        depressed
        dark
        color="green"
        :disabled="!valid || !productIsPickedItemInStock"
        :loading="loading"
        @click="onAddToCart"
      >
        {{ $t('components.website.productCard.addToCart') }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import ProductSizePicker from './ProductSizePicker.vue'
  import ProductColorPicker from './ProductColorPicker.vue'
  import Product from '@peynman/press-vue-core/mixins/Product'
  import FormValidations from '@peynman/press-vue-core/mixins/FormValidations'
  import ProductQuantityPicker from './ProductQuantityPicker.vue'

  export default {
    name: 'ProductAddToCartForm',
    components: {
      ProductSizePicker,
      ProductColorPicker,
      ProductQuantityPicker,
    },
    mixins: [
      Product,
      FormValidations(),
    ],
    props: {
      value: Object,
    },
    data: vm => ({
      valid: false,
      loading: false,
      submittingContactMe: false,
    }),
    methods: {
      onColorChanged () {
        const index = this.getImageIndexWithColor(this.color)
        if (index >= 0) {
          this.$emit('image-index', index)
        }
      },
      onAddToCart () {
        this.valid = this.$refs.form.validate()

        if (this.valid) {
          this.loading = true
          this.$store.dispatch('cart/addToCart', {
            product: this.product,
            quantity: this.quantity,
            extra: {
              color: this.pickedColorHex,
              size: this.size,
              name: this.pickedColorName,
              itemId: this.pickedItemId,
              parentId: this.pickedParentId,
            },
          })
            .then(json => {
              this.$store.commit('snackbar/addMessage', {
                message: this.$t('components.website.productCard.addToCartSuccess'),
                type: 'success',
                color: 'green',
              })
            })
            .catch(err => {
              this.$store.commit('snackbar/addMessage', {
                message: err.message,
                type: 'error',
                color: 'red',
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      onContactMeWhenAvailable () {
        if (this.$store.getters.isLoggedIn) {
          this.submittingContactMe = true
          this.$store.dispatch('product/fillProductContactForm', {
            userId: this.$store.getters['profile/user'].id,
            id: this.productId,
            itemId: this.pickedItemId,
            parentId: this.pickedParentId,
            name: this.pickedColorName,
            color: this.pickedColorHex,
            size: this.size,
          })
            .then(json => {
              this.$store.commit('snackbar/addMessage', {
                message: this.$t('components.website.productCard.contactMeWhenAvailableSuccess'),
                type: 'success',
                color: 'green',
              })
            })
            .catch(err => {
              this.$store.commit('snackbar/addMessage', {
                message: err.message,
                type: 'error',
                color: 'red',
              })
            })
            .finally(() => {
              this.submittingContactMe = false
            })
        } else {
          this.$store.commit('openSigninDialog')
        }
      },
    },
  }
</script>
