<template>
  <v-slide-group v-model="internalValue" show-arrows @change="$emit('input', internalValue)">
    <v-slide-item
      v-for="(img,i) in images"
      :key="`product-${productId}-carousel-${i}`"
    >
      <v-btn tile @click="changeImageIndex(i)">
        <v-img :src="getImageThumbnail(img.image)" width="60px" contain />
      </v-btn>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
  import Themable from '@peynman/press-vue-core/mixins/Themeable'

  export default {
    name: 'ProductImagesSlider',
    mixins: [
      Themable,
    ],
    props: {
      value: [Number, String],
      images: Array,
      productId: [String, Number],
      imageThumbnailFunc: Function,
    },
    data: vm => ({
      internalValue: vm.value,
      carouselHeight: 'auto',
    }),
    watch: {
      value () {
        this.internalValue = this.value
      },
    },
    methods: {
      getImageThumbnail (img) {
        return this.imageThumbnailFunc ? this.imageThumbnailFunc(img, 2) : img
      },
      changeImageIndex (i) {
        this.internalValue = i
        this.$emit('input', this.internalValue)
      },
    },
  }
</script>
