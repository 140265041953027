<template>
  <v-carousel
    v-model="internalValue"
    :hide-delimiters="true"
    :hide-delimiter-background="true"
    :show-arrows="images && images.length > 1 && !hideArrows"
    height="auto"
  >
    <v-carousel-item
      v-for="(img, i) in images"
      :key="`product-${productId}-carousel-${i}`"
    >
      <v-responsive v-resize="onResize" :aspect-ratio="img.aspect">
        <v-img
          :src="getImageThumbnail(img.image)"
          contain
          data-track-content
          data-content-piece="ProductImage"
          :data-content-name="`Product-${productId}-Image-${i}`"
          :data-content-target="getImageThumbnail(img.image)"
          :alt="getImageAltString(img, i)"
          @click="$emit('show-image', i, img)"
        >
          <template v-slot:placeholder>
            <v-row align="center" justify="center" class="fill-height">
              <v-progress-circular indeterminate />
            </v-row>
          </template>
        </v-img>
      </v-responsive>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  import Themable from '@peynman/press-vue-core/mixins/Themeable'

  export default {
    name: 'ProductImagesCarousel',
    mixins: [Themable],
    props: {
      value: [Number, String],
      images: Array,
      productId: [String, Number],
      imageThumbnailFunc: Function,
      hideArrows: Boolean,
    },
    data: vm => ({
      internalValue: vm.value,
    }),
    watch: {
      value () {
        this.internalValue = this.value
      },
    },
    methods: {
      getImageThumbnail (img) {
        return this.imageThumbnailFunc ? this.imageThumbnailFunc(img, 1) : img
      },
      getImageAltString (img, i) {
        return this.$t('components.website.productCard.imageAlt', {
          color: '',
          size: '',
          categories: '',
          title: '',
        })
      },
      onResize (size) {
        this.$emit('redraw')
      },
    },
  }
</script>
