<template>
  <div
    v-if="fixedPrice"
    class="d-flex flex-column flex-grow-1 align-center justify-center mb-3"
    style="position: relative;"
  >
    <div v-if="offAmount" style="position: absolute; top: 12px;" class="mt-2 d-flex flex-row justify-center align-center">
      <v-chip outlined :color="theme.website.product.card.priceTag.secondary" label small dense>
        <span :class="`strikethrough ${theme.website.product.card.priceTag.secondaryText}`">{{ offAmountString }}</span>
      </v-chip>
    </div>
    <div class="d-flex flex-row justify-center align-center">
      <v-chip
        :dark="theme.website.product.card.priceTag.dark"
        :color="theme.website.product.card.priceTag.primary"
        label
        small
        dense
        elevation="3"
      >
        <span :class="`font16 ${theme.website.product.card.priceTag.primaryText}`">{{ fixedPriceString }} </span>
      </v-chip>
    </div>
  </div>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import Product from '@peynman/press-vue-core/mixins/Product'

  export default {
    name: 'ProductCardPrice',
    mixins: [
      Themeable,
      Product,
    ],
    props: {
      value: Object,
    },
  }
</script>
