<template>
  <div class="d-flex flex-row justify-end align-center my-1">
    <v-tooltip top>
      <template v-slot:activator="{on}">
        <v-btn
          small
          icon
          :color="isFavorited ? 'red':''"
          :loading="loading"
          @click="onToggleFavorite"
          v-on="on"
        ><v-icon small>{{ isFavorited ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon></v-btn>
      </template>
      {{ $t('components.website.productCard.favorite') }}
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{on}">
        <v-btn
          small
          icon
          @click="onShare"
          v-on="on"
        ><v-icon small>mdi-share-variant</v-icon></v-btn>
      </template>
      {{ $t('components.website.productCard.share') }}
    </v-tooltip>
  </div>
</template>

<script>
  import ProductReviews from '@peynman/press-vue-core/mixins/ProductReviews'
  import User from '@peynman/press-vue-core/mixins/User'

  export default {
    name: 'ProductLinks',
    mixins: [
      ProductReviews,
      User,
    ],
    props: {
      value: Object,
    },
    data: vm => ({
      loading: false,
      syncingGiv: false,
    }),
    methods: {
      onShare () {

      },
      onToggleFavorite () {
        if (this.isLoggedIn) {
          this.loading = true
          this.toggleProductFavorite()
            .finally(() => {
              this.loading = false
            })
        } else {
          this.$store.commit('openSigninDialog')
        }
      },
      syncGivProduct () {
        this.syncingGiv = true
        this.$store.dispatch('apiCall', {
          url: `/api/giv/sync/product/${this.product.id}`,
          method: 'POST',
        })
          .then(json => {
            console.log(json)
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.syncingGiv = false
          })
      },
    },
  }
</script>
