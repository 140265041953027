<template>
  <v-hover v-slot="{ hover }">
    <v-card-subtitle
      :class="`d-flex ${horizontal ? 'flex-row justify-end':'flex-column justify-end'} pa-1`"
      :style="`${horizontal ? ((!blend || hover) ? 'opacity: 1;' : 'opacity: 0.6;') : 'position: absolute; left: -5px; bottom: -5px;'}`"
    >
      <v-chip
        v-for="cat in productCategoriesSubtitle"
        :key="`product-${productId}-category-${cat.id}`"
        class="mb-1 me-1"
        :color="cat.data.backColor"
        dense
        x-small
        label
        :to="`/shop/category/${cat.id}`"
      >
        <v-icon
          v-if="cat.data.icon"
          x-small
          class="me-1"
          :color="cat.data.textColor"
        >{{ cat.data.icon }}</v-icon>
        <span class="font-farsi" :style="`color: ${cat.data.textColor}`">
          {{ cat.data.title }}
        </span>
      </v-chip>
    </v-card-subtitle>
  </v-hover>
</template>

<script>
  import Product from '@peynman/press-vue-core/mixins/Product'

  export default {
    name: 'ProductCategoriesSubtitle',
    mixins: [Product],
    props: {
      value: Object,
      horizontal: Boolean,
      blend: Boolean,
    },
    computed: {
      productCategoriesSubtitle () {
        return this.productCategories?.filter(c => !c.data?.showAsProductBadge)
      },
    },
  }
</script>
