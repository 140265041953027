<template>
  <div v-if="productRibbon" style="z-index: 2;">
    <div :class="productRibbonType" :style="ribbonStyle">
      <div class="content" :style="contentStyle">
        {{ productRibbonContent }}
      </div>
    </div>
  </div>
</template>

<script>
  import Product from '@peynman/press-vue-core/mixins/Product'

  export default {
    name: 'ProductCardRibbon',
    mixins: [Product],
    props: {
      value: Object,
    },
    computed: {
      ribbonStyle () {
        return Object.entries({
          '--color': this.productRibbonColor,
          'border-color': this.productRibbonColor,
        }).map(e => `${e[0]}: ${e[1]}`).join(';')
      },
      contentStyle () {
        return Object.entries({
          '--color': this.productRibbonColor,
          'background-color': this.productRibbonColor,
          color: this.productRibbonTextColor,
          'font-size': this.productRibbonTextSize,
        }).map(e => `${e[0]}: ${e[1]}`).join(';')
      },
    },
  }
</script>
