<template>
  <v-input
    :label="$t('components.website.productCard.availableSizes')"
    :rules="rules"
    :error-messages="errorMessages"
    :vertical="true"
    :value="hasValidValue"
  >
    <v-btn-toggle v-model="internalValue" shaped class="mt-2" @change="onStockSizePicked">
      <v-btn
        v-for="(s, index) in stock"
        :key="`product-${productId}-size-${index}`"
        small
      >
        {{ s.size }}
      </v-btn>
    </v-btn-toggle>
  </v-input>
</template>

<script>
  export default {
    name: 'ProductSizePicker',
    props: {
      productId: [Number, String],
      stock: Array,
      value: String,
      rules: Array,
      errorMessages: Array,
    },
    data: vm => ({
      internalValue: vm.stock?.findIndex(s => s.size === vm.value),
    }),
    computed: {
      hasValidValue () {
        return Number.isSafeInteger(this.internalValue) && this.internalValue >= 0
      },
    },
    watch: {
      value () {
        this.internalValue = this.stock.findIndex(s => s.size === this.value)
      },
      stock () {
        this.internalValue = this.stock.findIndex(s => s.size === this.value)
      },
      internalValue () {
        if (Number.isSafeInteger(this.internalValue) && this.internalValue >= 0) {
          this.$emit('input', this.stock[this.internalValue].size)
        } else {
          this.$emit('input', undefined)
        }
      },
    },
    methods: {
      onStockSizePicked (v) {
      },
    },
  }
</script>
